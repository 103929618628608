import VueRouter from 'vue-router'
import walletView from '@/views/walletView'
import stakeView from '@/views/stakeView'
import swapView from '@/views/swapView'
import swapCrossView from '@/views/swapCrossView'
import swapOnRampView from '@/views/swapOnRampView'
import nftView from '@/views/nftView'
import daoProfilView from "@/views/daoProfilView";
import daoShillersView from "@/views/daoShillersView";
import daoFAQView from "@/views/daoFAQView";
import infoInfoView from "@/views/infoInfoView";
import infoRoadmapView from "@/views/infoRoadmapView";
import infoFAQView from "@/views/infoFAQView";
import legalView from "@/views/legalView";

const routes = [
    {
        path: '/',
        name: 'wallet',
        component: walletView
    },
    {
        path: '/stake',
        name: 'stake',
        component: stakeView
    },
    {
        path: '/swap',
        name: 'swap',
        component: swapView
    },
    {
        path: '/crossswap',
        name: 'crossswap',
        component: swapCrossView
    },
    {
        path: '/onramp',
        name: 'onramp',
        component: swapOnRampView
    },
    {
        path: '/nft',
        name: 'nft',
        component: nftView
    },
    {
        path: '/profil',
        name: 'profil',
        component: daoProfilView
    },
    {
        path: '/shillers',
        name: 'shillers',
        component: daoShillersView
    },
    {
        path: '/daofaq',
        name: 'daofaq',
        component: daoFAQView
    },
    {
        path: '/roadmap',
        name: 'roadmap',
        component: infoRoadmapView
    },
    {
        path: '/info',
        name: 'info',
        component: infoInfoView
    },
    {
        path: '/faq',
        name: 'faq',
        component: infoFAQView
    },
    {
        path: '/legal',
        name: 'legal',
        component: legalView
    }
]
const router = new VueRouter({
    routes // short for `routes: routes`
})
export default router