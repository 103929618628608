/* eslint-disable no-unused-vars */
import { ChainId, Fetcher, Route, Trade, Token, TokenAmount, TradeType } from "quickswap-sdk";
// import { ChainId, Fetcher, Route } from "quickswap-sdk";
import { ethers } from "ethers";
import abi from "@/resources/NSFW.json";

/* eslint-enable no-unused-vars */

function getStringBalancee(balance) {
    let maticBal = balance.toString();

    if(maticBal.length <= 18) {
        maticBal = String(maticBal).padStart(19, '0');
    }

    const newString = maticBal.slice(0, maticBal.length - 18 )
        + '.'
        + maticBal.slice(maticBal.length - 18, maticBal.length - 18 + 6);

    return newString;
}

const state = () => ({
    isconnected: false,
    wallet: null,
    web3: null,
    provider: null,
    contract: null,
    usdcBalanceRaw: null,
    nsfwBalanceRaw: null,
    nsfwStacked4BalanceRaw: null,
    nsfwStacked12BalanceRaw: null,
    nsfwclaimable4BalanceRaw: null,
    nsfwclaimable12BalanceRaw: null,
    approved4ValueRaw: null,
    approved12ValueRaw: null,
    stacke4unlockDate: null,
    stacke12unlockDate: null,
    maticBalanceRaw: null,
    nsfwPerUsdc: null
})

// getters
const getters = {
    nsfwContract: () => {
        return ethers.utils.getAddress('0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19');
    },
    isconnected: (state) => {
        return state.isconnected;
    },
    wallet: (state) => {
        return state.wallet;
    },
    web3: (state) => {
        return state.web3;
    },
    provider: (state) => {
        return state.provider;
    },
    contract: (state) => {
        return state.contract;
    },
    usdcBalanceRaw: (state) => {
        return state.usdcBalanceRaw;
    },
    usdcBalance: (state) => {
        if(state.usdcBalanceRaw) {
            return getStringBalancee(state.usdcBalanceRaw)
        }
        return 0;
    },
    nsfwBalanceRaw: (state) => {
        return state.nsfwBalanceRaw;
    },
    nsfwBalance: (state) => {
        if(state.nsfwBalanceRaw) {
            return getStringBalancee(state.nsfwBalanceRaw)
        }
        return 0;
    },
    nsfwStacked4BalanceRaw: (state) => {
        return state.nsfwBalanceRaw;
    },
    nsfwStacked4Balance: (state) => {
        if(state.nsfwStacked4BalanceRaw) {
            return getStringBalancee(state.nsfwStacked4BalanceRaw)
        }
        return 0;
    },
    nsfwStacked12BalanceRaw: (state) => {
        return state.nsfwStacked12BalanceRaw;
    },
    nsfwStacked12Balance: (state) => {
        if(state.nsfwStacked12BalanceRaw) {
            return getStringBalancee(state.nsfwStacked12BalanceRaw)
        }
        return 0;
    },
    nsfwclaimable4BalanceRaw: (state) => {
        return state.nsfwclaimable4BalanceRaw;
    },
    nsfwclaimable4Balance: (state) => {
        if(state.nsfwclaimable4BalanceRaw) {
            return getStringBalancee(state.nsfwclaimable4BalanceRaw)
        }
        return 0;
    },
    nsfwclaimable12BalanceRaw: (state) => {
        return state.nsfwclaimable12BalanceRaw;
    },
    nsfwclaimable12Balance: (state) => {
        if(state.nsfwclaimable12BalanceRaw) {
            return getStringBalancee(state.nsfwclaimable12BalanceRaw)
        }
        return 0;
    },
    approved4ValueRaw: (state) => {
        return state.approved4ValueRaw;
    },
    approved4Value: (state) => {
        if(state.approved4ValueRaw) {
            return getStringBalancee(state.approved4ValueRaw)
        }
        return 0;
    },
    approved12ValueRaw: (state) => {
        return state.approved12ValueRaw;
    },
    approved12Value: (state) => {
        if(state.approved12ValueRaw) {
            return getStringBalancee(state.approved12ValueRaw)
        }
        return 0;
    },
    stacke4unlockDate: (state) => {
        return state.stacke4unlockDate;
    },
    stacke12unlockDate: (state) => {
        return state.stacke12unlockDate;
    },
    maticBalanceRaw: (state) => {
        return state.maticBalanceRaw;
    },
    maticBalance: (state) => {
        if(state.maticBalanceRaw) {
            return getStringBalancee(state.maticBalanceRaw)
        }
        return 0;
    },
    nsfwPerUsdc: (state) => {
        if(state.nsfwPerUsdc) {
            return state.nsfwPerUsdc
        }
        return 0;
    }
}
// mutations
const mutations = {
    setConnected(state, payload) {
        state.isconnected = payload;
    },
    setWallet(state, payload) {
        state.wallet = payload;
    },
    setProvider(state, payload) {
        state.provider = payload;
    },
    setWeb3(state, payload) {
        state.web3 = payload;
    },
    setContract(state, payload) {
        state.contract = payload;
    },
    setUSDCBalance(state, payload) {
        state.usdcBalanceRaw = payload;
    },
    setNsfwBalance(state, payload) {
        state.nsfwBalanceRaw = payload;
    },
    nsfwStacked4Balance(state, payload) {
        state.nsfwStacked4BalanceRaw = payload;
    },
    nsfwStacked12Balance(state, payload) {
        state.nsfwStacked12BalanceRaw = payload;
    },
    nsfwclaimable4Balance(state, payload) {
        state.nsfwclaimable4BalanceRaw = payload;
    },
    nsfwclaimable12Balance(state, payload) {
        state.nsfwclaimable12BalanceRaw = payload;
    },
    approved4Value(state, payload) {
        state.approved4ValueRaw = payload;
    },
    approved12Value(state, payload) {
        state.approved12ValueRaw = payload;
    },
    setstacke4unlockDate(state, payload) {
        state.stacke4unlockDate = payload;
    },
    setstacke12unlockDate(state, payload) {
        state.stacke12unlockDate = payload;
    },
    setMaticBalance(state, payload) {
        state.maticBalanceRaw = payload;
    },
    setnsfwPerUsdc(state, payload) {
        state.nsfwPerUsdc = payload;
    }
}

// actions
const actions = {
    async setWeb3(context, instance) {
        //console.log(context);

        console.log('setWeb3');

        context.commit('setWeb3', instance);

        const provider = new ethers.providers.Web3Provider(instance);
        const walletAddress = await provider.getSigner().getAddress()

        console.log(walletAddress);

        context.commit('setWallet', walletAddress);
        context.commit('setProvider', provider);
        context.commit('setConnected', true);
        localStorage.setItem('isWalletConnected', 'true')
        await context.dispatch('refreshWallet', context);
    },
    async refreshWallet(context) {
        //console.log('refreshWallet called')

        const ethBalance = await context.getters.provider.getBalance(context.getters.wallet);
        context.commit('setMaticBalance', ethBalance);

        const nsfwContract = ethers.utils.getAddress('0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19');
        const nsfwContractSt4 = ethers.utils.getAddress('0xAad3B603623032d9941AF26599a256830fba2254');
        const nsfwContractSt12 = ethers.utils.getAddress('0x84ED758f73C994D61B4a119c06700889C8463F8B');


        const abi = require("../resources/NSFW.json");
        const erc20_nsfw = new ethers.Contract(nsfwContract, abi, context.getters.provider);
        const nsfwBalance = await erc20_nsfw.balanceOf(context.getters.wallet);
        context.commit('setNsfwBalance', nsfwBalance);

        // USDC Balance
        const usdcContract = ethers.utils.getAddress('0x2791bca1f2de4661ed88a30c99a7a9449aa84174');
        const erc20_usdc = new ethers.Contract(usdcContract, abi, context.getters.provider);
        const usdcBalance = await erc20_usdc.balanceOf(context.getters.wallet);
        context.commit('setUSDCBalance', usdcBalance);

        // Staking
        const abi4 = require("../resources/NSFWStaking.json");
        const erc20_nsfw4s = new ethers.Contract(nsfwContractSt4, abi4, context.getters.provider);
        const nsfwBalance4 = await erc20_nsfw4s.getStakedBalance(context.getters.wallet);
        context.commit('nsfwStacked4Balance', nsfwBalance4);

        const abi12 = require("../resources/NSFWStaking.json");
        const erc20_nsfw12s = new ethers.Contract(nsfwContractSt12, abi12, context.getters.provider);
        const nsfwBalance12 = await erc20_nsfw12s.getStakedBalance(context.getters.wallet);
        context.commit('nsfwStacked12Balance', nsfwBalance12);

        // getUserReward
        const nsfwClaim4 = await erc20_nsfw4s.getUserReward(context.getters.wallet);
        context.commit('nsfwclaimable4Balance', nsfwClaim4);

        const nsfwClaim12 = await erc20_nsfw12s.getUserReward(context.getters.wallet);
        context.commit('nsfwclaimable12Balance', nsfwClaim12);

        const nsfw4minUnlockDate = await erc20_nsfw4s.getMinimalUnlockDate(context.getters.wallet);
        //console.log(nsfw4minUnlockDate)
        const date4 = new Date(nsfw4minUnlockDate * 1000);
        context.commit('setstacke4unlockDate', date4);

        const nsfw12minUnlockDate = await erc20_nsfw12s.getMinimalUnlockDate(context.getters.wallet);
        //console.log(nsfw12minUnlockDate)
        const date12 = new Date(nsfw12minUnlockDate * 1000);
        context.commit('setstacke12unlockDate', date12);

        const approve4w = await erc20_nsfw.allowance(context.getters.wallet, nsfwContractSt4);
        context.commit('approved4Value', approve4w);
        // console.log(approve4w);

        const approve12w = await erc20_nsfw.allowance(context.getters.wallet, nsfwContractSt12);
        context.commit('approved12Value', approve12w);
        // console.log(approve12w);

        /* eslint-disable no-unused-vars */
        // Get USDC per NSFW
        const maticAdress = ethers.utils.getAddress('0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270');
        const nsfwAdress = ethers.utils.getAddress('0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19');
        const usdcAdress = ethers.utils.getAddress('0x2791bca1f2de4661ed88a30c99a7a9449aa84174');

        // https://polygon-mainnet.infura.io/v3/a35a78fd438f4aeeb7c0b1a46b141b34
        const customHttpProvider = new ethers.providers.JsonRpcProvider('https://crimson-muddy-water.matic.quiknode.pro/c86a863eefb58c23712da87fbb11cf0bae9e81a5/');
        // const customHttpProvider = new ethers.providers.JsonRpcProvider('https://polygon-mainnet.infura.io/v3/a35a78fd438f4aeeb7c0b1a46b141b34');


        const nsfwFetcher = await Fetcher.fetchTokenData(ChainId.MATIC, nsfwAdress, customHttpProvider);
        const usdcFetcher = await Fetcher.fetchTokenData(ChainId.MATIC, usdcAdress, customHttpProvider);

        const nsfwDecimals = nsfwFetcher.decimals;
        const usdcDecimals = usdcFetcher.decimals;

        const nsfwToken = new Token(ChainId.MATIC, nsfwAdress, nsfwDecimals)
        const usdcToken = new Token(ChainId.MATIC, usdcAdress, usdcDecimals)

        const pair = await Fetcher.fetchPairData(usdcToken, nsfwToken, customHttpProvider);
        // console.log(pair);

        const route = new Route([pair], nsfwToken);
        // console.log(route);

        // console.log(route.midPrice.toSignificant(18));
        // console.log(route.midPrice.invert().toSignificant(18));

        context.commit('setnsfwPerUsdc', route.midPrice.invert().toSignificant(18));
    },
    setMaticBalance(context, payload) {
        console.log(context);
        context.commit('setMaticBalance', payload);
    },
    async claimReward4week(context) {

        const abi = require("../resources/NSFWStaking.json");
        const signer = context.getters.provider.getSigner();

        const erc20_nsfwSt4w = new ethers.Contract(ethers.utils.getAddress('0xAad3B603623032d9941AF26599a256830fba2254'), abi, signer);
        const receipt = await erc20_nsfwSt4w.claimReward()

        //console.log(receipt);

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')

        context.getters.provider.waitForTransaction(receipt.hash, 1, 60000)
            .then(await context.dispatch('sendMessage', 'Claiming validated'))
            .catch(
                err => context.dispatch('sendError', 'Claiming maybe failed!' + err)
            )
            .finally(await context.dispatch('refreshWallet', context))
        
    },
    async claimReward12week(context) {

        const abi = require("../resources/NSFWStaking.json");
        const signer = context.getters.provider.getSigner();

        const erc20_nsfwSt12w = new ethers.Contract(ethers.utils.getAddress('0x84ED758f73C994D61B4a119c06700889C8463F8B'), abi, signer);
        const receipt = await erc20_nsfwSt12w.claimReward()

        //console.log(receipt);

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')

        context.getters.provider.waitForTransaction(receipt.hash, 1, 60000)
            .then(await context.dispatch('sendMessage', 'Claiming validated'))
            .catch(
                err => context.dispatch('sendError', 'Claiming maybe failed!' + err)
            )
            .finally(await context.dispatch('refreshWallet', context))
    },
    async approve(context, payload) {

        const abi = require("../resources/NSFW.json");
        const signer = context.getters.provider.getSigner();

        const nsfwContract = ethers.utils.getAddress('0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19');

        const erc20_nsfw = new ethers.Contract(nsfwContract, abi, signer);
        //const erc20_nsfwSt4w = new ethers.Contract(ethers.utils.getAddress('0xAad3B603623032d9941AF26599a256830fba2254'), abi, signer);
        //'0xAad3B603623032d9941AF26599a256830fba2254'
        const receipt = await erc20_nsfw.approve(ethers.utils.getAddress(payload.toAddress), payload.amount)

        //console.log(receipt);

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')

        context.getters.provider.waitForTransaction(receipt.hash, 1, 60000)
            .then(await context.dispatch('sendMessage', 'Approve validated'))
            .catch(
                err => context.dispatch('sendError', 'Approve maybe failed!' + err)
            )
            .finally(await context.dispatch('refreshWallet', context))

    },
    async transferNSFW(context, payload) {

        // this.$emit('showMessage','insiS')

        const toAddress = ethers.utils.getAddress(payload.toAddress)
        const trfAmount = ethers.utils.parseUnits(payload.amount)
        console.log(payload)
        console.log("toAddress : " + toAddress)
        console.log("Amount : " + trfAmount)

        const abi = require("../resources/NSFW.json");
        const signer = context.getters.provider.getSigner();

        const erc20_nsfwContract = new ethers.Contract(ethers.utils.getAddress('0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19'), abi, signer);

        const gasPrice = await context.getters.provider.getGasPrice()
        const gasLimit = 2000000

        //const gasCost = Number(ethers.utils.formatEther(gasPrice.mul(gasLimit)));

        const options = { gasLimit: gasLimit, gasPrice: gasPrice };
        console.log(options)


        const receipt = await erc20_nsfwContract.transfer(
            ethers.utils.getAddress(payload.toAddress),
            ethers.utils.parseUnits(payload.amount), options
        );

        await context.dispatch('sendMessage', 'Find your tx on <a style="color: black" target=\'_blank\' href=\'https://polygonscan.com/tx/'+receipt.hash+'\'>polygonscan</a>')

        context.getters.provider.waitForTransaction(receipt.hash, 1, 60000)
            .then(await context.dispatch('sendMessage', 'Transfer validated'))
            .catch(
                err => context.dispatch('sendError', 'Transfer maybe failed!' + err)
            )
            .finally(await context.dispatch('refreshWallet', context))

        // this.$emit('showMessage',receipt.hash)
        // this.$router.app.$emit('showMessage',receipt.hash)

        console.log(receipt);

        //await provider.sendTransaction(signedTx);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}