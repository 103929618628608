<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        TODO: Users DAO Profile, connect Telegram, Discord, Twitter, Reddit to earn Rewards
        <daoProfile></daoProfile>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import daoProfile from "@/components/daoProfile";

export default {
  name: "daoProfilView",
  components: {
    daoProfile
  }
}
</script>

<style scoped>

</style>