<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        TODO: Global Token and Project infos.
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "infoInfoView"
}
</script>

<style scoped>

</style>