<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        TODO: Shows the users NFTs at Polygon Network and mybe functions for the pleasureNifty Marketplace.
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "nftView"
}
</script>

<style scoped>

</style>