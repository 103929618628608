<template>
  <div class="bd-example">

    <b-input-group prepend="FROM:" class="mt-3">
      <b-form-input readonly v-model="selectedBalance"></b-form-input>
      <b-input-group-append>
        <b-form-select v-model="selected" :options="options"></b-form-select>
        <b-button>{{ selected }}</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-form-input id="range-st4" v-model="fromAmountSliderValue" type="range" step="5"></b-form-input>

    <b-form-group
        id="input-group-1"
        label="QUICKSWAP:"
        label-for="input-1"
        description="Using Quickswap LP"
    >
      <b-input-group id="input-1" prepend="FOR:" class="mt-3">
        <b-form-input readonly v-model="quickSwap"></b-form-input>
        <b-input-group-append>
          <b-form-select v-model="swapForSelected" :options="swapForOptions"></b-form-select>
          <b-button>SWAP</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
        id="input-group-2"
        label="UNISWAP:"
        label-for="input-2"
        description="Using Uniswap LP"
    >
      <b-input-group id="input-2" prepend="FOR:" class="mt-3">
        <b-form-input readonly v-model="uniSwap"></b-form-input>
        <b-input-group-append>
          <b-form-select v-model="swapForSelected" :options="swapForOptions"></b-form-select>
          <b-button>SWAP</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
        id="input-group-3"
        label="PARASWAP:"
        label-for="input-3"
        description="Using Paraswaps Multiswap"
    >
      <b-input-group id="input-3" prepend="FOR:" class="mt-3">
        <b-form-input readonly v-model="paraSwap"></b-form-input>
        <b-input-group-append>
          <b-form-select v-model="swapForSelected" :options="swapForOptions"></b-form-select>
          <b-button>SWAP</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

  </div>
</template>

<script>

// import { ChainId } from "quickswap-sdk";

export default {
  name: "multiSwap",
  data() {
    return {
      fromAmount:0,
      fromAmountSliderValue:0,
      quickSwap: null,
      quickSwapSelected: null,
      uniSwap: null,
      uniSwapSelected: null,
      paraSwap: null,
      paraSwapSelected: null,
      selected: 'USDC',
      options: [
        { value: 'MATIC', text: 'MATIC' },
        { value: 'USDC', text: 'USDC' },
        { value: 'NSFW', text: 'N$SW' },
      ],
      swapForSelected: 'NSFW',
      swapForOptions: [
        { value: 'MATIC', text: 'MATIC' },
        { value: 'USDC', text: 'USDC' },
        { value: 'NSFW', text: 'N$SW' },
      ]
    }
  },
  watch: {
    selected: function (val) {
      //console.log('new: %s, old: %s', val, oldVal)

      if(val === 'NSFW') {
        this.swapForOptions = [
          { value: 'MATIC', text: 'MATIC' },
          { value: 'USDC', text: 'USDC' },
        ];
        this.swapForSelected = 'USDC'
      } else {
        this.swapForOptions = [
          {value: 'NSFW', text: 'N$SW'}
        ];
        this.swapForSelected = 'NSFW'
      }
    }
  },
  computed: {
    selectedBalance: function () {
      switch (this.selected) {
        case 'NSFW':
          return this.$store.getters.nsfwBalance
        case 'MATIC':
          return this.$store.getters.maticBalance
        case 'USDC':
          return this.$store.getters.usdcBalance
        default:
          return 0
      }

    }
  }
}
</script>

<style scoped>
.bd-example {
  padding: 1.5rem;
  border: solid #dee2e6;
  border-width: 2px;
  border-radius: .5rem;
}
</style>