<template>
  <v-chart class="chart" :option="option" ref="mchart" id="myChart"/>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  LegendComponent,
  GridComponent
} from 'echarts/components';
import VChart from 'vue-echarts';
import { ref, defineComponent } from 'vue';

use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent
]);

export default defineComponent({
  name: "nsfwCharts",
  components: {
    VChart,
  },
  data() {
    return {
      cData: [1000, 500, 1000, 500, 1000, 500, 1320]
    }
  },
  methods: {
    onResize() {
      // console.log("resize");
      this.$refs.mchart.resize();
    }
  },
  setup() {
    const option = ref({
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#fff'
          }
        }
      },
      series: [
        {
          data: [],
          type: 'line',
          smooth: true,
          areaStyle: {
            color: '#340654',
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
          },
          emphasis: {
            scale: false,
            scaleSize: 20
          }
        }
      ]
    });

    return { option };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    const self = this;

    fetch("https://api.coingecko.com/api/v3/coins/pleasure-coin/market_chart?vs_currency=usd&days=1")
        .then(async response => {
          const data = await response.json();

          // check for error response
          /*
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          console.log(data);
          */

          const xdata = [];
          const ydata = [];

          for (let i = 0; i < data.prices.length; i++){
            const obj = data.prices[i];
            // console.log('time: ' + obj[0]);
            //const date = new Date(obj[0]);
            //xdata.push(date);
            xdata.push(obj[0]);
            ydata.push(obj[1]);
          }

          self.$refs.mchart.setOption({
            xAxis: {
                //data: xdata
          },
            series: [
              {
                data: ydata
              }
            ]
          });
        })
        .catch(error => {
          //this.errorMessage = error;
          console.error("There was an error!", error);
        });
  }
});
</script>

<style>
.chart {
  height: 400px;
}
</style>
