<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        <multiSwap></multiSwap>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import multiSwap from "@/components/multiSwap";

export default {
  name: "swapView",
  components : {
    multiSwap
  }
}
</script>

<style scoped>

</style>