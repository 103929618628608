<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        <nsfw-balance ></nsfw-balance>
        <nsfwTransaction></nsfwTransaction>
        <nsfwCharts></nsfwCharts>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import nsfwBalance from '../components/nsfwBalance'
import nsfwCharts from '../components/nsfwCharts'
import nsfwTransaction from "@/components/nsfwTransaction";

export default {
  name: "wallet",
  components: {
    nsfwBalance,
    nsfwCharts,
    nsfwTransaction
  },
  methods: {

  },
}
</script>

<style scoped>

</style>