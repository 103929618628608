import { render, staticRenderFns } from "./daoProfilView.vue?vue&type=template&id=56910f13&scoped=true&"
import script from "./daoProfilView.vue?vue&type=script&lang=js&"
export * from "./daoProfilView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56910f13",
  null
  
)

export default component.exports