<template>
  <div>
    <div>

    </div>
  </div>
</template>

<script>
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// import { ethers } from "ethers";

export default {
  name: "web3Handler",
  data: function () {
    return {
      web3Provider: {},
      etherS: false,
      timer: null
    }
  },
  methods: {
    connect: async function () {

      const providerOptions = {
        Metamask: {
          display: {
            // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
            name: "Metamask",
            description: "Connect with your Browser Wallet"
          },
          package: null
        },
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: undefined,
            rpc: {
              137: 'https://polygon-mainnet.infura.io/v3/a35a78fd438f4aeeb7c0b1a46b141b34'
            }
          }
        }
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
        disableInjectedProvider: false,
      });

      try {
        this.web3Provider = await web3Modal.connect();
      } catch(e) {
        console.log("Could not get a wallet connection", e);
        return;
      }

      console.log("connect provider");

      this.$store.commit('setConnected', true);

      await this.$store.dispatch('setWeb3', this.web3Provider);

      const walletAddress = this.$store.getters.wallet

      this.web3Provider.on(walletAddress, (newBalance) => {
        console.log("newBalance " + newBalance);
        this.$store.dispatch('refreshWallet');
      });

      this.web3Provider.on("accountsChanged", (accounts) => {
        console.log("accountsChanged " + accounts);
        this.$store.dispatch('setWeb3', this.$store.getters.web3);
      });

      // Subscribe to chainId change
      this.web3Provider.on("chainChanged", (chainId) => {
        console.log("chainChanged " + chainId);
      });

      // Subscribe to session disconnection
      this.web3Provider.on("disconnect", (code, reason) => {
        console.log("disconnect " + code, reason);
        this.$store.commit('setConnected', false);
      });

    },
    disconnect: async function () {
      console.log("disconnect click");
      console.log(this.web3Provider);
      console.log(this.etherS);
      // this.web3Provider.disconnect();
      // this.etherS.web3Provider.disconnect();
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.timer = setInterval(() => {
        this.$store.dispatch('refreshWallet');
      }, 30000)
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }

}
</script>

<style scoped>

</style>