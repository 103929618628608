import { render, staticRenderFns } from "./stakeView.vue?vue&type=template&id=39db3ebd&scoped=true&"
import script from "./stakeView.vue?vue&type=script&lang=js&"
export * from "./stakeView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39db3ebd",
  null
  
)

export default component.exports