<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        TODO: Impress etc.<br>And a "Buy me a coffee/pizza/caipirinha link" for me ;)
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "legalView"
}
</script>

<style scoped>

</style>