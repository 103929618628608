<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        <nsfwStake></nsfwStake>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import nsfwStake from '../components/nsfwStake'

export default {
  name: "wallet",
  components: {
    nsfwStake
  },
}
</script>

<style scoped>

</style>