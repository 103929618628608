<template>
  <div class="bd-example">
    <b-card header-tag="header" footer-tag="footer">
      <b-card-title>Send</b-card-title>

      <b-input-group prepend="Address:" class="mt-3">
        <b-form-input type="text" v-model="transferAddress" required :state="transferAddressState" placeholder="Wallet 0x1234...."></b-form-input>
      </b-input-group>
      <b-input-group prepend="Amount:" class="mt-3">
        <b-form-input v-model="transferAmount"></b-form-input>
        <b-input-group-append>
          <b-button :disabled="this.transferAmount < 100" @click="transferNSFW">Transfer</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-input :disabled="this.balance < 100" id="range-st4" v-model="transferAmount" type="range" min="100" :max="this.balance" step="5"></b-form-input>
    </b-card>
  </div>
</template>

<script>
import { ethers } from "ethers";

export default {
  name: "nsfwTransaction",
  data() {
    return {
      insliderValue: 0,
      transferAmount: 0,
      transferAddressData: '',
      msg: [],
    }
  },
  computed: {
    balance() {
      return this.$store.getters.nsfwBalance;
    },
    transferAddress:{
      get() {
        try {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          return ethers.utils.getAddress(this.transferAddressData)
        }catch (e) {
          return this.transferAddressData
        }
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        try {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.transferAddressData = ethers.utils.getAddress(newValue)
        }catch (e) {
          this.transferAddressData = newValue
        }
      }
    },
    setAmount() {
      if(this.insliderValue > 0 && this.balance && this.balance > 0) {

        let tVal = (this.balance / 100 * this.insliderValue).toString();
        if(tVal.length >= tVal.indexOf(".")+6) {
          tVal = tVal.substring(0, tVal.indexOf(".")+6);
        }
        return tVal;
      } else {
        return 0;
      }
    },
    transferAddressState() {
      if(!this.transferAddress || this.transferAddress.len < 2) {
        return null;
      }

      try {
       ethers.utils.getAddress(this.transferAddress);
       return true;
      }catch (e) {
        //console.warn(e)
        return false
      }
    }
  },
  watch: {

  },
  methods: {
    transferNSFW: function () {
      //console.log("transferNSFW clicked - " + this.transferAddress);
      this.$store.dispatch('transferNSFW', {'toAddress': this.transferAddress, 'amount': this.transferAmount});

      this.transferAmount = 0;
    }
  }
}
</script>

<style scoped>

</style>