const state = () => ({
    currentMessage: null,
    currentError: null,
})

const getters = {
    getMessage (state) {
        return state.currentMessage
    },
    getError (state) {
        return state.currentError
    }
}

const actions = {
    sendMessage (context, msg) {
        context.commit('sendMessage', msg)
    },
    sendError (context, err) {
        context.commit('sendError', err)
    }
}

const mutations = {
    sendMessage (state, msg) {
        state.currentMessage = msg
    },
    sendError (state, err) {
        state.currentError = err
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}