import Vue from 'vue'
import Vuex from 'vuex'
import contractStore from './contractStore';
import messageStore from './messageStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        contractStore,
        messageStore
    }
})