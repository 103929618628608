<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col>
        TODO: Shows cross Swap options. First to implement is BSC Chain, BNB and BUSD
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "crossswapView"
}
</script>

<style scoped>

</style>