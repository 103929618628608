<template>
  <div>
    <b-modal id="modal-1" title="What’s staking?" style="color:black;" ok-only>
      <p class="my-4">Staking is locking up your tokens for a period of either 4 or 12 weeks in order to earn passive income.
        Staking for 4 weeks will give you an APY of 4%.
        Staking for 12 weeks will give you an APY of 10%.

        The NSFW rewards will be unlocked to participants weekly
      </p>
      <h3>Staking is as easy as 1, 2, 3</h3>
      <p class="my-4">
        <b-badge>1</b-badge>Connect to the site (On Polygon Network)<br>
        <b-badge>2</b-badge>Choose 4 or 12 Weeks and Enter the amount you want to stake (1 million minimum)<br>
        <b-badge>3</b-badge>Approve the deposit<br>
        <b-badge>4</b-badge>Stake the deposit<br>
      </p>
      <h3>Things to remember:</h3>
      <p class="my-4">
        You can stake at any point, but the rewards only begin generating in the following week. So if you start staking on Wednesday of week 1, the 4 week period starts in the following week (week 2) and lasts for either 4 or 12 weeks from then, depending on the pool chosen.

        You can add to your staking amount, but the staking period resets based on your last deposit. So if you stake for 4 weeks in week 1, but top up in week 2, your 4 weeks period resets and lasts until the end of week 6.

        If at the end of the staking period, a user doesn’t manually unlock their stake, they will be auto-re-staked for the same period.

        To unlock the stake at the end of the period, the request must be made using the “Unlock” button any time before the final week. Eg a 4 weeks staking period ends in week 5 - if you don’t want to renew, click the “Unlock” button in week 1-4.

        Unlock date is written in DD/MM/YYYY format.

        Once a stake is unlocked, rewards are no longer earned and NSFW can be withdrawn by using the withdraw button.
      </p>
    </b-modal>

    <div class="bd-example">
      <label for="balance-1">N$FW: {{ this.$store.getters.nsfwBalance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"></label>
      <p id="balance-1">You need at least 1.000.000 N$FW in your wallet to be able to use approve, transfer or stake</p>
    </div>

    <!-- 4 weeks -->
    <div class="bd-example">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-title>
          4 weeks staking N$FW: {{ this.$store.getters.nsfwStacked4Balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png">
          <b-button v-b-modal.modal-1 pill variant="info" size="sm">INFO</b-button>
        </b-card-title>

        <b-input-group prepend="Claimable:" class="mt-3">
          <b-form-input readonly v-model="nsfwclaimable4Balance"></b-form-input>
          <b-input-group-append>
            <b-button @click="claim4w" :disabled="nsfwclaimable4Balance < 1" >Claim</b-button>
          </b-input-group-append>
        </b-input-group>


        <b-input-group prepend="Approve:" class="mt-3">
          <b-form-input readonly v-model="stake4sliderValue"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="this.$store.getters.nsfwBalance < 1000000" @click="approve4w">Approve</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-input :disabled="this.$store.getters.nsfwBalance < 1000000" id="range-st4" v-model="stake4sliderValue" type="range" min="1000000" :max="this.$store.getters.nsfwBalance" step="5"></b-form-input>

        <b-card v-if="stake4sliderValue > 0 " title="Return on 4% APY" class="mb-2">
          <b-input-group prepend="per Week:" class="mt-3">
            <b-form-input readonly v-model="stake4perWeekReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Month:" class="mt-3">
            <b-form-input readonly v-model="stake4perMonthReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Year:" class="mt-3">
            <b-form-input readonly v-model="stake4perYearReturn"></b-form-input>
          </b-input-group>
        </b-card>

        <b-input-group prepend="Transfer:" class="mt-3">
          <b-form-input readonly v-model="approved4Value"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="this.$store.getters.approved4Value < 1000000">Transfer</b-button>
            <b-button :disabled="this.$store.getters.approved4Value < 1000000">Revoke</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-input-group prepend="Unlockable in :" class="mt-3">
          <b-form-input readonly v-model="unlockDate4local"></b-form-input>
          <b-form-input readonly value="days"></b-form-input>
          <b-input-group-append>
            <b-button disabled>Unlock</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-card>
    </div>

    <!-- 12 weeks -->
    <div class="bd-example">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-title>12 weeks staking N$FW: {{ this.$store.getters.nsfwStacked12Balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png">
          <b-button v-b-modal.modal-1 pill variant="info" size="sm">INFO</b-button>
        </b-card-title>

        <b-input-group prepend="Claimable:" class="mt-3">
          <b-form-input readonly v-model="nsfwclaimable12Balance"></b-form-input>
          <b-input-group-append>
            <b-button @click="claim12w" :disabled="nsfwclaimable12Balance < 1" >Claim</b-button>
          </b-input-group-append>
        </b-input-group>


        <b-input-group prepend="Approve:" class="mt-3">

          <b-form-input readonly v-model="stake12sliderValue"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="this.$store.getters.nsfwBalance < 1000000" @click="approve12w">Approve</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-form-input :disabled="this.$store.getters.nsfwBalance < 1000000" id="range-st12" v-model="stake12sliderValue" type="range" min="1000000" :max="this.$store.getters.nsfwBalance" step="5"></b-form-input>

        <b-card v-if="stake12sliderValue > 0 " title="Return on 12% APY" class="mb-2">
          <b-input-group prepend="per Week:" class="mt-3">
            <b-form-input readonly v-model="stake12perWeekReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Month:" class="mt-3">
            <b-form-input readonly v-model="stake12perMonthReturn"></b-form-input>
          </b-input-group>
          <b-input-group prepend="per Year:" class="mt-3">
            <b-form-input readonly v-model="stake12perYearReturn"></b-form-input>
          </b-input-group>
        </b-card>

        <b-input-group prepend="Transfer:" class="mt-3">
          <b-form-input readonly v-model="approved12Value"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="this.$store.getters.approved12Value < minStakeValue">Transfer</b-button>
            <b-button :disabled="this.$store.getters.approved12Value < minStakeValue">Revoke</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-input-group prepend="Unlockable in :" class="mt-3">
          <b-form-input readonly v-model="unlockDate12local"></b-form-input>
          <b-form-input readonly value="days"></b-form-input>
          <b-input-group-append>
            <b-button disabled>Unlock</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-card>
    </div>

  </div>
</template>

<script>

import moment from 'moment'

import {ethers} from "ethers";

export default {
  name: "nsfwStake",
  data() {
    return {
      insliderValue: 0,
      stake4sliderValue: 0,
      stake12sliderValue: 0,
      minStakeValue: 1000000,
      stake4visible: false
    }
  },
  methods: {
    sliderClick: function () {
      alert("Click")
    },
    claim4w: function () {
      console.log("Claim 4 clicked");
      this.$store.dispatch('claimReward4week');
    },
    claim12w: function () {
      console.log("Claim 12 clicked");
      this.$store.dispatch('claimReward12week');
    },
    approve4w: function () {
      if(this.stake4sliderValue >= this.minStakeValue) {
        const transferAddress = ethers.utils.getAddress('0xAad3B603623032d9941AF26599a256830fba2254'); // 4 week contract
        this.$store.dispatch('approve', {'toAddress': transferAddress, 'amount': this.stake4sliderValue});
      }
    },
    approve12w: function () {
      if(this.stake12sliderValue >= this.minStakeValue) {
        const transferAddress = ethers.utils.getAddress('0xAad3B603623032d9941AF26599a256830fba2254'); // 12 week contract
        this.$store.dispatch('approve', {'toAddress': transferAddress, 'amount': this.stake12sliderValue});
      }
    }
  },
  computed: {
    balance() {
      return this.$store.getters.nsfwBalance;
    },
    nsfwclaimable4Balance() {
      if(this.$store.getters.nsfwclaimable4Balance) {
        return this.$store.getters.nsfwclaimable4Balance
      }
      return '';
    },
    approved4Value() {
      if(this.$store.getters.approved4Value) {
        return this.$store.getters.approved4Value
      }
      return '';
    },
    stake4perWeekReturn() {
      if(this.stake4sliderValue > 0) {
        return ((this.stake4sliderValue * 0.04) / 53)
      }
      return '';
    },
    stake4perMonthReturn() {
      if(this.stake4sliderValue > 0) {
        return ((this.stake4sliderValue * 0.04) / 53) * 4
      }
      return '';
    },
    stake4perYearReturn() {
      if(this.stake4sliderValue > 0) {
        return this.stake4sliderValue * 0.04
      }
      return '';
    },
    stake12perWeekReturn() {
      if(this.stake12sliderValue > 0) {
        return ((this.stake12sliderValue * 0.1) / 53)
      }
      return '';
    },
    stake12perMonthReturn() {
      if(this.stake12sliderValue > 0) {
        return ((this.stake12sliderValue * 0.1) / 53) * 4
      }
      return '';
    },
    stake12perYearReturn() {
      if(this.stake12sliderValue > 0) {
        return this.stake12sliderValue * 0.1
      }
      return '';
    },
    approved12Value() {
      if(this.$store.getters.approved12Value) {
        return this.$store.getters.approved12Value
      }
      return '';
    },
    unlockDate4local() {
      if(this.$store.getters.stacke4unlockDate) {
        return moment(this.$store.getters.stacke4unlockDate).diff(moment(),'days')
      }
      return '';
    },
    unlockDate12local() {
      if(this.$store.getters.stacke12unlockDate) {
        return moment(this.$store.getters.stacke12unlockDate).diff(moment(),'days')
      }
      return '';
    },
    nsfwclaimable12Balance() {
      if(this.$store.getters.nsfwclaimable12Balance) {
        return this.$store.getters.nsfwclaimable12Balance
      }
      return '';
    },
    setAmount() {
      if(this.insliderValue > 0 && this.balance && this.balance > 0) {

        let tVal = (this.balance / 100 * this.insliderValue).toString();
        if(tVal.length >= tVal.indexOf(".")+6) {
          tVal = tVal.substring(0, tVal.indexOf(".")+6);
        }
        return tVal;
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped>
.bd-example {
  padding: 1.5rem;
  border: solid #dee2e6;
  border-width: 2px;
  border-radius: .5rem;
}

</style>