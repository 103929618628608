<template>
  <div class="bd-example">
    <p>Using stand-alone:<p/>
    <div class="mb-4">
      <b-avatar variant="warning" size="lg"></b-avatar>
      <b-input-group prepend="Telegram:" class="mt-3">
        <b-form-input readonly></b-form-input>
        <b-input-group-append>
          <b-button>Connect</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-input-group prepend="Discord:" class="mt-3">
        <b-form-input readonly></b-form-input>
        <b-input-group-append>
          <b-button>Connect</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-input-group prepend="Twitter:" class="mt-3">
        <b-form-input readonly></b-form-input>
        <b-input-group-append>
          <b-button>Connect</b-button>
        </b-input-group-append>
      </b-input-group>

      <label for="earned-progress">Earned points: 564556</label>
        <b-progress id="earned-progress" aria-describedby="password-help-block" class="mt-2" :max="max" show-value>
          <b-progress-bar :value="45 * (6 / 10)" variant="success"></b-progress-bar>
          <b-progress-bar :value="45 * (2.5 / 10)" variant="warning"></b-progress-bar>
          <b-progress-bar :value="45 * (1.5 / 10)" variant="danger"></b-progress-bar>
        </b-progress>
      <b-form-text id="password-help-block">
        Lore ipsum lalalal
      </b-form-text>

    </div>
  </div>
</template>

<script>
export default {
  name: "daoProfile",

}
</script>

<style scoped>

</style>