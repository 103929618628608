<template>
  <div style="position:fixed; right:10px; top: 60px; z-index: 9999;">
    <b-alert :show="errordismissCountDown"
             dismissible
             fade
             @dismissed="showErrorDismissed"
             @dismiss-count-down="errorcountDownChanged"
             variant="danger"
             style="z-index: 9999;"><span v-html="currentError"></span>

      <b-progress
          variant="danger"
          :max="errordismissSecs"
          :value="errordismissCountDown"
          height="4px"
      ></b-progress>
    </b-alert>
    <b-alert :show="messagedismissCountDown"
             v-html=currentMessage
             dismissible
             fade
             @dismissed="showMessageDismissed"
             @dismiss-count-down="messagecountDownChanged"
             style="z-index: 9999;">{{ currentMessage }}
      <b-progress
          variant="success"
          :max="messagedismissSecs"
          :value="messagedismissCountDown"
          height="4px"
      ></b-progress>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "messageHandler",
  data: function () {
    return {
      messagedismissSecs: 6,
      messagedismissCountDown: 0,
      currentMessage: null,
      msgS: [],
      errordismissSecs: 6,
      errordismissCountDown: 0,
      currentError: null,
      errorS: []
    }
  },
  methods: {
    showMessage(msg) {
      if(msg) {
        this.msgS.push(msg)
        this.showMessageDismissed()
      }
    },
    showError(err) {
      if(err) {
        this.errorS.push(err)
        this.showErrorDismissed()
      }
    },
    showMessageDismissed() {
      if(this.messagedismissCountDown <= 0) {
        this.messagedismissCountDown = 0
        if (this.msgS.length > 0) {
          this.currentMessage = this.msgS[0];
          this.msgS.shift()
          this.messagedismissCountDown = this.messagedismissSecs
        } else {
          this.currentMessage = null
        }
      }
    },
    showErrorDismissed() {
      if(this.errordismissCountDown <= 0) {
        this.errordismissCountDown = 0
        if (this.errorS.length > 0) {
          this.currentError = this.errorS[0];
          this.errorS.shift()
          this.errordismissCountDown = this.errordismissSecs
        } else {
          this.currentError = null
        }
      }
    },
    messagecountDownChanged(messagedismissCountDown) {
      this.messagedismissCountDown = messagedismissCountDown
    },
    errorcountDownChanged(errordismissCountDown) {
      this.errordismissCountDown = errordismissCountDown
    }
  },
  computed: {
    messageChange () {
      return this.$store.getters.getMessage
    },
    errorChange () {
      return this.$store.getters.getError
    }
  },
  watch:{
    messageChange(value) {
      this.showMessage(value)
      this.$store.dispatch('sendMessage', null)
    },
    errorChange(value) {
      this.showError(value)
      this.$store.dispatch('sendError', null)
    }
  }
}
</script>

<style scoped>

</style>