<template>
<div>
  <b-button
      v-on:click="connect()"
      v-if="!this.$store.getters.isconnected"
  >Connect</b-button>
  <b-button
      v-on:click="disconnect()"
      v-if="this.$store.getters.isconnected"
  >{{ walletAdd }}</b-button>
</div>
</template>

<script>
export default {
  name: "ConnectButton",
  computed: {
    walletAdd: function () {
      if(this.$store.getters.wallet) {
        return this.$store.getters.wallet.slice(0, 3) +".."+this.$store.getters.wallet.slice(-3) + " - Matic " + this.$store.getters.maticBalance;
      }
      return "";
    }
  },
  methods: {
    connect: async function() {
      console.log("connect click");
      this.$emit('connectClick');
      },
    disconnect: async function() {
      console.log("disconnect click");
      this.$emit('disconnectClick');
      },
  }
}
</script>

<style scoped>

</style>