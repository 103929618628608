<template>
  <div>
    <div class="bd-example">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-title>Balance</b-card-title>
        <b-card-text>
          N$FW: {{ this.$store.getters.nsfwBalance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"> =~ $ {{ this.balanceInUSDC }}<br>
          Staked 4 N$FW: {{ this.$store.getters.nsfwStacked4Balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"> =~ $ {{ this.nsfwStacked4InUSDC }}<br>
          Staked 12 N$FW: {{ this.$store.getters.nsfwStacked12Balance }} <img alt="PleasureCoin" src="../assets/pc_x22.png"> =~ $ {{ this.nsfwStacked12USDC }}
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: "nsfwBalance",
  data() {
    return {
      insliderValue: 0,
      stake4sliderValue: 0,
      stake12sliderValue: 0,
      stake4visible: false
    }
  },
  methods: {

  },
  computed: {
    balance() {
      return this.$store.getters.nsfwBalance;
    },
    balanceInUSDC() {
      if(this.$store.getters.nsfwBalance && this.$store.getters.nsfwPerUsdc) {
        return parseFloat(this.$store.getters.nsfwBalance / this.$store.getters.nsfwPerUsdc).toFixed(2);
      }
      return 0;
    },
    nsfwStacked4InUSDC() {
      if(this.$store.getters.nsfwStacked4Balance && this.$store.getters.nsfwPerUsdc) {
        return parseFloat(this.$store.getters.nsfwStacked4Balance / this.$store.getters.nsfwPerUsdc).toFixed(2);
      }
      return 0;
    },
    nsfwStacked12USDC() {
      if(this.$store.getters.nsfwStacked12Balance && this.$store.getters.nsfwPerUsdc) {
        return parseFloat(this.$store.getters.nsfwStacked12Balance / this.$store.getters.nsfwPerUsdc).toFixed(2);
      }
      return 0;
    },
    unlockDate4local() {
      if(this.$store.getters.stacke4unlockDate) {
        return moment(this.$store.getters.stacke4unlockDate).diff(moment(),'days')
      }
      return false;
    },
    unlockDate12local() {
      if(this.$store.getters.stacke12unlockDate) {
        return moment(this.$store.getters.stacke12unlockDate).diff(moment(),'days')
      }
      return false;
    },
    setAmount() {
      if(this.insliderValue > 0 && this.balance && this.balance > 0) {

        let tVal = (this.balance / 100 * this.insliderValue).toString();
        if(tVal.length >= tVal.indexOf(".")+6) {
          tVal = tVal.substring(0, tVal.indexOf(".")+6);
        }
        return tVal;
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped>
.bd-example {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
</style>