import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import '@forevolve/bootstrap-dark/dist/css/toggle-bootstrap.min.css'
// import '@forevolve/bootstrap-dark/dist/css/toggle-bootstrap-dark.min.css'
// import '@forevolve/bootstrap-dark/dist/css/toggle-bootstrap-print.min.css'
import VueAnalytics from 'vue-analytics';

import router from './router/index.js';
import store from './store/index.js';


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-237881668-1',
  router
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')